<template>
  <div class="list">
    <div class="list_b">
      <ul>
        <li v-if="$store.state.User.jibieId==0">
          <p>所属门店：</p>
          <select @change="getEmpList" v-model="form.fenpeimendianId">
            <option :value="item.id" v-for="item in storeList" :key="item.id">{{item.storeName}}</option>
          </select>
        </li>
        <li>
          <p>项目类型：</p>
          <select @change="resetItemLx" v-model="form.itemleixing">
            <option :value="0">全部</option>
            <option :value="1">商品</option>
            <option :value="2">项目</option>
          </select>
        </li>
        <li v-if="$store.state.User.daoqishijian>=0">
          <p>员工：</p>
          <select @change="getData(1)" v-model="form.userId">
            <option :value="null">全部</option>
            <option :value="item.id" v-for="item in EmpList" :key="item.id">{{item.name}}</option>
          </select>
        </li>
        <li>
          <p>时间：</p>
          <select v-model="form.Sdate" @change="addDate">
            <option v-for="item in dateList" :key="item.id" :value="item.id">{{item.name}}</option>
          </select>
        </li>
        <li>
          <button type="button" @click="getData(1)">查询</button>
        </li>
        <div class="clear"></div>
      </ul>
    </div>
    <div class="home_list">
      <div class="list_d">
        <table v-if="tableShow">
          <thead>
            <tr>
              <td style="text-align:center">排名</td>
              <td style="text-align:center">类型</td>
              <td>名称</td>
              <td style="text-align:center">总次数</td>
              <td style="text-align:center">次卡次数</td>
              <td>操作</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item,i) in dataList" :key="item.itemId">
              <td style="text-align:center">{{i+1}}</td>
              <td style="text-align:center">{{item.itemleixing==1?"商品":item.itemleixing==2?"项目":""}}</td>
              <td>{{item.itemName}}</td>
              <td style="text-align:center"><strong><span class="err">{{item.Count}}</span></strong></td>
              <td style="text-align:center">{{item.cs}}</td>
              <td>
                <div class="buttonGroup">
                  <button type="button" class="suc" @click="setInfoData(item)">详情</button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <table v-else>
          <colgroup>
            <col>
            <col width="90">
          </colgroup>
          <thead>
            <tr>
              <td style="text-align:center">编号</td>
              <td style="text-align:center">日期</td>
              <td style="text-align:center">类型</td>
              <td>名称</td>
              <td style="text-align:center">原价</td>
              <td style="text-align:center">实价</td>
              <td style="text-align:center">次卡实价</td>
              <td style="text-align:center">数量</td>
              <td style="text-align:center">次卡数量</td>
              <td style="text-align:center">总价</td>
              <td style="text-align:center">实收</td>
              <td >服务人员</td>
              <td style="text-align:center">状态</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in dataList" :key="item.id">
              <td style="text-align:center">{{item.id}}</td>
              <td style="text-align:center">{{dataFormat(item.xiaofeishijian)}}</td>
              <td style="text-align:center">{{returnLB(item.itemleixing)}}</td>
              <td>{{item.itemName}}</td>
              <td style="text-align:center">{{item.defjiage}}</td>
              <td style="text-align:center">{{item.yingshou/item.num}}</td>
              <td style="text-align:center">{{item.cikaticheng}}</td>
              <td style="text-align:center">{{item.num}}</td>
              <td style="text-align:center">{{item.xiaofeicishu}}</td>
              <td style="text-align:center">{{item.yingshou}}</td>
              <td style="text-align:center">{{item.shishou}}</td>
              <td >{{item.fwryNames}}</td>
              <td style="text-align:center"><span :class="returnStateClass(item.state)">{{returnState(item.state)}}</span></td>
            </tr>
          </tbody>
        </table> 
      </div>
    </div>
    <div class="list_c">
      <div class="c_buttom">
        <button type="button" class="suc" v-if="!tableShow" @click="resetBack">返回</button>
      </div>
      <Page :pageData="pageData" @resetData="resetData" v-if="!tableShow"></Page>
      <div class="clear"></div>
    </div>
  </div>
</template>

<script>
import axios from '@/http/axios'
import moment from 'moment'
export default {
  name: 'OperationRecord',
  data() {
    return {
      dateList:[
        {
          id:1,
          name:'今日',
          startDate:moment().format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:2,
          name:'昨日',
          startDate:moment().add(-1,"d").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"d").format("YYYY-MM-DD"),
        },
        {
          id:3,
          name:'本周',
          startDate:moment().startOf("W").format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:4,
          name:'上周',
          startDate:moment().add(-1,"W").startOf("W").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"W").endOf("W").format("YYYY-MM-DD"),
        },
        {
          id:5,
          name:'本月',
          startDate:moment().startOf("M").format("YYYY-MM-DD"),
          endDate:null,
        },
        {
          id:6,
          name:'上月',
          startDate:moment().add(-1,"M").startOf("M").format("YYYY-MM-DD"),
          endDate:moment().add(-1,"M").endOf("M").format("YYYY-MM-DD"),
        },

      ],
      tableShow:true,
      dataList:[],
      storeList:[],
      EmpList:[],
      form:{
        fenpeimendianId:this.$store.state.User.fenpeimendianId,
        itemleixing:0,
        Sdate:1,
        startDate:moment().format("YYYY-MM-DD"),
        endDate:null,
        userId:null,
        itemId:null,
      },
      pageData:{
        pageSize:20,
        pageIndex:1,
        total:0,
      },
    }
  },
  created() {
    if(this.$store.state.User.jibieId==0) this.getStore()
    else this.getData()
  },
  components: {},
  mounted() {},
  methods: {
    resetItemLx(){
      this.form.itemId=null
      this.getData(1)
    },
    restPH(){
      this.tableShow=true
      this.form.userId=null
      this.CalendarShow=false
      this.getData()
    },
    setInfoData(item){
      this.tableShow=false
      this.form.itemleixing=item.itemleixing
      this.form.itemId=item.itemId
      this.getData(1)
    },
    resetTC(tc){
      if(tc) return tc.toFixed(2)
      else return '0.00'
    },
    addDate(){
      let d=this.dateList.find(e=>e.id==this.form.Sdate)
      this.form.startDate=d.startDate
      this.form.endDate=d.endDate
      this.getData(1)
      
    },
    resetBack(){
      this.form.itemleixing=0
      this.form.itemId=null
      this.tableShow=true
      this.getData(1)
    },
    dataFormat(str){
      if(str) return moment(str).format("YYYY-MM-DD HH:mm:ss")
      else return ''
    },
    returnStateClass(lb){
      if(lb==1){
        return 'suc'
      }
      else{
        return 'err'
      }
    },
    returnState(lb){
      if(lb==1){
        return '正常'
      }
      else{
        return '撤销'
      }
    },
    returnLB(lb){
      if(lb==1){
        return '商品'
      }
      else if(lb==2){
        return '项目'
      }
      else if(lb==3){
        return '充值'
      }
    },
    getData(index){
      if(this.tableShow){
        axios.post('/dataCount/itemCount',this.form).then((res) => {
          if(res.code===5000){
            this.dataList=res.data
          }else this.$Mesg({content: '获取数据失败！',errOrSuc: 'err'})
        })
      }else{
        if(index) this.pageData.pageIndex=1
        axios.post('/dataCount/itemCountInfo',{...this.form,...this.pageData}).then((res) => {
          if(res.code===5000){
            this.dataList=res.data
            this.pageData.total=res.total
          }else this.$Mesg({content: '获取数据失败！',errOrSuc: 'err'})
        })
      }
      
    },
    resetData(data){
      this.pageData=data
      this.getData()
    },
    getStore(){
      axios.post('/store/list').then((res) => {
        if(res.code===5000){
          this.storeList=res.data
          this.getEmpList()
        }else{
          this.$Mesg({content: res.msg,errOrSuc: 'err'})
        }
      })
    },
    getEmpList(){
      axios.post('/emp/list',{fenpeimendianId:this.form.fenpeimendianId}).then((res) => {
        if(res.code===5000){
          this.EmpList=res.data.map(e=>{
            return{
              id:e.id,
              name:e.xingming,
            }
          })
          this.form.userId=null
          this.getData(1)
        }else{this.$Mesg({content: res.msg,errOrSuc: 'err'})}
      })
    },
    
  },
}
</script>
